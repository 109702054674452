import {Component, Input, OnDestroy} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs/internal/Subscription';
import {ValidationService} from '../../shared/services/validation.service';
@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss'],
  imports: [],
  standalone: true,
})
export class ControlMessagesComponent implements OnDestroy {
  @Input() public label?: string;
  errors = new Set<string>();
  latestError: string | undefined;
  errorObject: {
    [key: string]: any;
  } = {};
  private sub: Subscription | undefined;
  @Input() set control(con: AbstractControl<any, any>) {
    this.sub = con.statusChanges.subscribe((value) => {
      if (con.errors) {
        this.errorObject = con.errors;
        let validationErrors = new Set<string>();
        for (const [key] of Object.entries(con.errors)) {
          this.errors.add(key);
          validationErrors.add(key);
        }
        this.errors.forEach((element) => {
          const foundElement = validationErrors.has(element);
          if (!foundElement) {
            // existing error in this.errors is not found anymore in the validation errors collection
            // means the error was removed from the control
            // thus I have to remove the error from the this.errors collection
            this.errors.delete(element);
          } else {
            this.latestError = element;
          }
        });
      } else {
        // when the control has no errors then clear the internal errors set too
        this.errors.clear();
        this.latestError = '';
      }
    });
  }
  constructor(public validationService: ValidationService) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
