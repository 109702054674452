import {Injectable, isDevMode} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public route(routePath: string): void {
    if (!isDevMode()) {
      window.location.assign(routePath);
    } else {
      console.log('route attempted to the following location', routePath);
    }
  }
}
