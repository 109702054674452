import {Injectable} from '@angular/core';
import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormGroup,
  FormControl,
} from '@angular/forms';
import {PLACEHOLDER_CONSTANT, VALIDATION_ERROR_CONSTANT} from '../../constant/constant';
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      //The valid email address  regex was found here: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
      const emailMatch = control.value?.match(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
      );
      return emailMatch ? null : {invalidEmailAddress: true};
    };
  }

  static passwordMatchValidator: ValidatorFn = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    if (!control.value.confirmPassword) return null;
    if (control.value.confirmPassword.length < control.value.password.length) return null;
    return control.value.password === control.value.confirmPassword
      ? null
      : {PasswordNoMatch: true};
  };

  public getValidatorErrorMessage(
    validatorName: string,
    validatorValue?: any,
    label?: string,
  ): string {
    const config: {
      [key: string]: any;
    } = {
      required: label + VALIDATION_ERROR_CONSTANT.IS_REQUIRED,
      invalidEmailAddress: `${VALIDATION_ERROR_CONSTANT.INVALID_EMAIL_ADDRESS} ${PLACEHOLDER_CONSTANT.EMAIL_FORMAT}`,
      minlength: $localize`:Minimum length@minimumLength:Minimum length ${validatorValue?.requiredLength}`,
      maxlength: $localize`:Maximum length@maximumLength:Maximum length ${validatorValue?.requiredLength}`,
      PasswordNoMatch: VALIDATION_ERROR_CONSTANT.PASSWORD_MISMATCH,
    };
    return config[validatorName];
  }

  public validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
