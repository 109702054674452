import {environment} from '../../../environments/environment';

export class ApiRouteUtil {
  static appClientDetails(orgId: string, appClientId: string): string {
    return `${environment.apiUrl}/orgs/${orgId}/apps/${appClientId}`;
  }

  static orgSearchByLogin(login: string): string {
    return `${environment.apiUrl}/orgs?login=${login}`;
  }

  static orgDetails(orgId: string): string {
    return `${environment.apiUrl}/orgs/${orgId}`;
  }

  static privacyNote(): string {
    return 'assets/markdown/privacyNote.md';
  }
}
